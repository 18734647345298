import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet";

import Layout from "../../components/Layout";
import BackButton from "../../components/BackButton";
import PhotoAlbum from "../../components/PhotoAlbum";

const AlbumHeader = styled.section`
    margin-top: 84px;
    padding: 0 18px;
    position: relative;
    h1 {
        font-size: 2.25rem;
        color: var(--green);
        text-align: center;
        position: relative;
        margin-bottom: 8px;
		padding-top: 30px;
    }
    h2 {
        font-size: .875rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        font-family: var(--roboto);
        color: var(--sand-med);
    }
    span {
        display: block;
        font-size: .6875rem;
        text-align: center;
        color: var(--sand-med);
		margin-bottom: 36px;
    }
`

export default function Knechts() {
    const basePics = useStaticQuery(graphql`
		{
			full: allFile(
				filter: {
                    relativeDirectory: {eq: "albums/knechts"},
                    name: {glob: "*-full"}}
                sort: {fields: name}
			) {
				edges {
					node {
						childImageSharp {
							full: gatsbyImageData(layout: FULL_WIDTH)
						}
						name
					}
				}
			}
			thumb: allFile(
                filter: {
                    relativeDirectory: {eq: "albums/knechts"},
                    name: {glob: "*-thumb"}}
                sort: {fields: name}
            ) {
                edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 320,
                            height: 320,
                            placeholder: BLURRED
                            )
                        }
                    name
                }
                }
            }
		}
	`);
    // 1. Creat an empty array to populate for the album
	let albumImages = [];

	// 2. Build array of thumbnails
	const thumbs = basePics.thumb.edges.map(({ node }) => node.childImageSharp);

	// 3. Set variable to iterate through thumbs array
	let thumbIterationNum = 0;

	// 4. Build and iterate over array of full size images
	basePics.full.edges.map(({ node }) => {
		albumImages.push({
			...thumbs[thumbIterationNum],
			...node.childImageSharp,
			thumbAlt: `Photo gallery ${node.name} thumbnail`,
			title: `Photo gallery ${node.name} full`,
		});
		thumbIterationNum++;
	});
    return (
        <Layout alwaysSticky={true}>
            <Helmet>
                <title>The Knechts :: Tide &amp; Tone Photography</title>
            </Helmet>
            <AlbumHeader>
                <BackButton hash="knechts" />
                {/* <AnchorLink to="/#team" title="Our team">
                    <span>Check out our team!</span>
                </AnchorLink> */}
                <h1>The Knechts</h1>
                <h2>Multi-Family Retreat :: Oak Island, NC</h2>
                <span>July, 2022</span>
            </AlbumHeader>
			<PhotoAlbum albumImages={albumImages} />
        </Layout>
    )
}